<template>
  <div>
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>

    <div class="consulting">
      <div class="consulting_tile">{{ list.title ? list.title : "" }}</div>
      <div class="consulting_fb">
        <img :src="list.columnCover" alt="" />
        <div>{{ transformDateTime(list.time) }}</div>
      </div>
      <div class="consulting_bor">
        <div v-for="(item, index) in list.contents" :key="index">
          <div v-if="item.type == 0" class="consulting_data">
            {{ item.content }}
          </div>
          <img :src="item.content" alt="" v-if="item.type == 1" />
          <video
            :src="item.content"
            v-if="item.type == 2"
            controls
            :poster="item.content"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import { ConsultationList } from "../../api/curriculum";
import axios from "axios";
import { transformDateTime } from "@/utils";
export default {
  components: {
    downBtn,
  },
  mixins: [mixin],
  created() {
    let url =
      process.env.NODE_ENV == "test" || process.env.NODE_ENV == "dev"
        ? "http://server-japanese-test.yangcongjiaoyu.com/"
        : "https://japanese-server.yangcongjiaoyu.com/";
    axios
      .get( url +
        "information/getInformationArticleDetail",
        {
          params: {
            id: this.$route.query.id,
            // id: 120,
          },
          headers: {
            token: this.$route.query.token,
            // token:
              // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzd29yZCI6IjQ5NkVERDgwNjQ4OTI4NjRCNzZDNUZEM0E3MzI1NDRCIiwiZXhwIjoxNjU5MTI5MTUwLCJhY2NvdW50IjoidTUzNTc4NzcxMzYifQ.-K0nA70k7J9P9Z2gJIaO3yYK3AWWBVk4-XtIi7zjsmw",
          },
        }
      )
      .then((res) => {
        this.list = res.data.data;
        console.log(this.list, 57541654);
      });
  },
  data() {
    return {
      list: null,
    };
  },
  methods: {
    transformDateTime,
    async addConsultationList() {
      const data = await ConsultationList({ id: 1 });
      console.log(data);
    },
  },
};
</script>

<style scoped lang="scss">
.consulting {
  margin-top: 45px;
  padding: 0px 12px;
  .consulting_tile {
    font-size: 18px;
    font-weight: 700;
  }
  .consulting_fb {
    font-size: 14px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  .consulting_bor {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      .consulting_data {
        font-size: 14px;
        margin-top: 10px;
        line-height: 30px;
      }
      img {
        width: 100%;
        height: 90px;
        margin-top: 10px;
      }
      video {
        width: 100%;
        height: 100px;
        margin-top: 10px;
      }
    }
  }
}
</style>